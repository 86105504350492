import { createClient, WixClient } from '@wix/sdk';
import { WixCodeAPI } from '@wix/blocks-widget-services-types';
import { get, invoke } from 'lodash';
import { ClientSdk, CreateClientParams } from '../services';

export const createClientSdk = (wixSdk: WixCodeAPI) => {
  return {
    async invoke({
      applicationId,
      namespace,
      method,
      args,
    }: {
      applicationId: string;
      namespace: string;
      method: string;
      args: unknown[];
    }): Promise<any> {
      try {
        const targetValueOrMethodToInvoke = get(
          wixSdk,
          `${namespace}.${method}`,
        );

        if (typeof targetValueOrMethodToInvoke === 'function') {
          return invoke(wixSdk, `${namespace}.${method}`, ...args);
        }

        return targetValueOrMethodToInvoke;
      } catch (e: any) {
        console.error('Wix SDK- Failed to invoke method', {
          applicationId,
          namespace,
          method,
          message: e.message,
        });
      }
    },
  };
};

export function createSdkProviders(
  wixSdk: any,
  createClientParams: CreateClientParams,
): {
  client: WixClient;
  clientSdk: ClientSdk;
} {
  const clientSdk = createClientSdk(wixSdk);

  const client = createClient(createClientParams);

  return { client, clientSdk };
}

export function getWixContext(
  wixSdk: WixCodeAPI,
  createClientParams?: CreateClientParams | undefined,
):
  | {
      version: number;
      client: WixClient;
      clientSdk: ClientSdk;
    }
  | undefined {
  if (!createClientParams) {
    return undefined;
  }

  const { client, clientSdk } = createSdkProviders(wixSdk, createClientParams);

  return {
    version: 3,
    client,
    clientSdk,
  };
}
