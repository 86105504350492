import { $Widget } from '@wix/blocks-widget-services-types';
import { RemoteModuleArgsBase } from './remoteModuleArgs';
import { AuthenticationStrategy, type WixClient } from '@wix/sdk';
import { type Host } from '@wix/sdk-types';

export type WidgetModuleArgs<T extends object> = {
  $widget: $Widget<T>;
  $wixContext?: WixContext;
  wixClient?: WixClient;
  widgetInstanceId?: string;
  onHMRCallback?: () => void;
} & RemoteModuleArgsBase;

export type WidgetModuleExports = Record<string, Function>;

export type WixContext = {
  version: number;
  client: WixClient;
  clientSdk: ClientSdk;
};
export type WidgetModule<T extends object> = (
  args: WidgetModuleArgs<T>,
) => Promise<WidgetModuleExports>;

export interface IWidgetModuleProvider {
  getModule: <T extends object>(widgetType: string) => WidgetModule<T>;
}

export type CreateClientParams = {
  host: Host;
  auth: AuthenticationStrategy;
};

export type ClientSdk = {
  invoke: (args: {
    applicationId: string;
    namespace: string;
    method: string;
    args: any[];
  }) => Promise<void>;
};

export enum ModuleProviderType {
  BundledCodeModuleProvider,
  AMDModuleProvider,
  DefaultModuleProvider,
}
