import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';

export function createAppRefreshHandler(
  appDefinitionId: string,
  wixCodeApi: IWixAPI,
) {
  /* istanbul ignore next */
  return async () => {
    /* istanbul ignore next */
    if (wixCodeApi.window.viewMode === 'Site') {
      /* istanbul ignore next */
      return;
    }
    /* istanbul ignore next */
    // @ts-expect-error
    await wixCodeApi.site.notifyEventToEditorApp(appDefinitionId, {
      eventType: 'refreshControllers',
    });
  };
}
