import { WidgetMetaData } from '@wix/blocks-widget-services-types';
import { IMetaDataProvider } from './IMetaDataProvider';
import { WidgetBundleProvider } from './widgetBundleProvider';

export class BundledMetaDataProvider implements IMetaDataProvider {
  constructor(private readonly provider: WidgetBundleProvider) {}

  async getMetaData(widgetType: string): Promise<WidgetMetaData> {
    try {
      const { api } = (await this.provider.loadBundle(widgetType))!;
      return (
        api ?? {
          properties: [],
          events: [],
          functions: [],
        }
      );
    } catch (e) {
      return { properties: [], events: [], functions: [] };
    }
  }
}
