import { WidgetControllerConfig } from '@wix/blocks-widget-services-types';
import {
  IWidgetModuleProvider,
  WidgetModuleArgs,
} from './IWidgetModuleProvider';
import { WidgetBundleProvider } from './widgetBundleProvider';
import {
  assertIsBlocksAppParamsWithWidgetBundleUrls,
  assertWixCodeApis,
} from '../utils/assertions';
import { createAppRefreshHandler } from '../utils/appRefreshHandler';

export class BundledWidgetModuleProvider implements IWidgetModuleProvider {
  private onHMRCallback: () => Promise<void>;
  constructor(
    private readonly provider: WidgetBundleProvider,
    private readonly controllerConfig: WidgetControllerConfig,
  ) {
    assertIsBlocksAppParamsWithWidgetBundleUrls(this.controllerConfig);
    assertWixCodeApis(this.controllerConfig.wixCodeApi);
    /* istanbul ignore next */
    this.onHMRCallback = createAppRefreshHandler(
      controllerConfig.appParams.appDefinitionId,
      controllerConfig.wixCodeApi,
    );
  }

  getModule<T extends object>(widgetType: string) {
    return async (globals: WidgetModuleArgs<T>) => {
      const { init } = (await this.provider.loadBundle(widgetType))!;
      return init({
        ...globals,
        widgetInstanceId: widgetType,
        wixClient: globals.$wixContext?.client,
        onHMRCallback: this.onHMRCallback,
      });
    };
  }
}
