import { WidgetModule } from './IWidgetModuleProvider';
import { loadBundledCode } from '../utils/importScriptBundledCodeModule';
import { WidgetControllerConfig } from '@wix/blocks-widget-services-types';
import { assertIsBlocksAppParamsWithWidgetBundleUrls } from '../utils/assertions';

export class WidgetBundleProvider {
  private _widgetBundleUrls: Record<string, string>;
  private _widgetBundlesCache: Map<
    string,
    Awaited<ReturnType<typeof loadBundledCode<WidgetModule<object>>>>
  > = new Map();

  constructor(controllerConfig: WidgetControllerConfig) {
    assertIsBlocksAppParamsWithWidgetBundleUrls(controllerConfig);
    this._widgetBundleUrls =
      controllerConfig.appParams.appData.blocksConsumerData.widgetBundleUrls;
  }

  async loadBundle(widgetType: string) {
    const widgetCodeUrl = this._widgetBundleUrls[widgetType];
    if (!this._widgetBundlesCache.has(widgetCodeUrl)) {
      this._widgetBundlesCache.set(
        widgetCodeUrl,
        await loadBundledCode<WidgetModule<object>>(widgetCodeUrl),
      );
    }
    return this._widgetBundlesCache.get(widgetCodeUrl);
  }
}
