import { WixCodeAPI } from '@wix/blocks-widget-services-types';
import { createClientSdk } from './sdkProvider';
import { site } from '@wix/site';
import { CreateClientParams } from '../services';

export function getApiBaseUrl(wixSdk: WixCodeAPI) {
  if (wixSdk.window?.viewMode !== 'Site') {
    return undefined;
  }

  const siteBaseUrl = wixSdk.location?.baseUrl;
  const siteUrl = new URL(siteBaseUrl);
  return siteUrl.hostname;
}

export const createSiteClientParams = (
  wixSdk: WixCodeAPI,
  applicationId: string,
  instance: string,
): CreateClientParams => {
  const apiBaseUrl = getApiBaseUrl(wixSdk);
  const clientSdk = createClientSdk(wixSdk);

  return {
    host: {
      ...site.host({
        applicationId,
        clientSdk,
      }),
      ...(apiBaseUrl !== undefined ? { apiBaseUrl } : {}),
      essentials: {
        language:
          wixSdk.window?.multilingual?.currentLanguage || wixSdk.site?.language,
        locale: wixSdk.window?.locale,
      },
    },
    auth: {
      getAuthHeaders() {
        /* istanbul ignore next */
        return {
          headers: {
            Authorization: instance.startsWith('OauthNG')
              ? instance
              : wixSdk.elementorySupport?.getRequestOptions().Authorization,
          },
        };
      },
    },
  };
};
